const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({}),

  getters: {},

  actions: {},

  mutations: {},
};
