const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    coin: {
      history: {},
      symbol: [],
      trade: {},
    },
    deposit: {
      qr: null,
      address: null,
      name: null,
    },
    actions: [],
    balance: {},
    infoDashboard: {},
  }),

  getters: {
    Coin: (state) => state.coin,
    Deposit: (state) => state.deposit,
    Actions: (state) => state.actions,
    Balance: (state) => state.balance,
    InfoDashboard: (state) => state.infoDashboard,
  },

  actions: {
    req_getListCoin({ commit }) {
      axios.get('api/v1/wallet/price').then((res) => {
        if (res) {
          commit('SET_LIST_COIN', res);
        }
      });
    },
    req_getBalance({ commit }) {
      axios.get('api/v1/auth/balance').then((res) => {
        if (res) {
          commit('SET_BALANCE_USER', res);
        }
      });
    },
    req_getInfoDashboard({ commit }) {
      axios.get('api/v1/dashboard').then((res) => {
        if (res) {
          commit('SET_INFO_DASHBOARD', res);
        }
      });
    },
    req_getListAction({ commit }) {
      axios.get('api/v1/wallet/action ').then((res) => {
        if (res) {
          commit('SET_LIST_ACTION', res);
        }
      });
    },
    req_getDepositInfo({ commit }, input) {
      axios
        .get('api/v1/wallet/address', {
          params: input,
        })
        .then((res) => {
          commit('SET_DEPOSIT_INFO', res);
        });
    },
    req_postWithdraw({ commit }, input) {
      axios.post('api/v1/wallet/withdraw', input).then((res) => {
        commit('WITHDRAW_SUCCESS', res);
      });
    },
    req_postTransfer({ commit }, input) {
      axios.post('api/v1/wallet/transfer', input).then((res) => {
        commit('TRANSFER_SUCCESS', res);
      });
    },
    req_postDepositDemo({ commit }, input) {
      axios.post('api/v1/wallet/deposit-demo', input).then((res) => {
        commit('DEPOSIT_SUCCESS', res);
      });
    },
  },

  mutations: {
    SET_LIST_COIN(state, data) {
      state.coin.history = data.history;
      state.coin.symbol = data.symbol;
      state.coin.trade = data.trade;
      this.dispatch('wallet/req_getListAction');
    },
    async setupWallet(state, input) {
      state.addr = input;
    },
    SET_DEPOSIT_INFO(state, data) {
      state.deposit = {
        qr: data.Qr,
        address: data.address,
      };
    },
    WITHDRAW_SUCCESS() {
      this.dispatch('wallet/req_getInfoDashboard');
      this.dispatch('history/req_getHistoryWallet');
    },
    TRANSFER_SUCCESS() {
      this.dispatch('wallet/req_getInfoDashboard');
      this.dispatch('history/req_getHistoryWallet');
    },
    DEPOSIT_SUCCESS() {
      this.dispatch('wallet/req_getInfoDashboard');
      this.dispatch('history/req_getHistoryWallet');
    },
    SET_LIST_ACTION(state, actions) {
      state.actions = actions;
    },
    SET_BALANCE_USER(state, data) {
      state.balance = data;
    },
    SET_INFO_DASHBOARD(state, data) {
      // console.log(data);
      state.infoDashboard = data;
    },
  },
};
