const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    listTradingSymbol: [],
    listStratege: [],
    detailBot: {},
    historyBot: {},
    historyBotProfit: {},
  }),

  getters: {
    ListTradingSymbol: (state) => state.listTradingSymbol,
    ListStratege: (state) => state.listStratege,
    DetailBot: (state) => state.detailBot,
    HistoryBot: (state) => state.historyBot,
    HistoryBotProfit: (state) => state.historyBotProfit,
  },

  actions: {
    req_getListTradingSymbol({ commit }, input) {
      axios.get('api/v1/bot/list-trading-symbol', input).then((res) => {
        if (res) {
          commit('GET_LIST_TRADING_SYMBOL', res);
        }
      });
    },
    req_getListStrategy({ commit }, input) {
      axios.get('api/v1/bot/list-strategy', input).then((res) => {
        if (res) {
          commit('GET_LIST_STRATEGE', res);
        }
      });
    },
    req_getDetailBot({ commit }, input) {
      axios.get('api/v1/bot/detail', input).then((res) => {
        if (res) {
          commit('GET_DETAILS_BOT', res);
        }
      });
    },
    req_postSaveOrUpdateBot({ commit }, input) {
      axios.post('api/v1/bot/post', input).then((res) => {
        if (res) {
          commit('UPDATE_BOT_SUCCESS', res);
        }
      });
    },
    req_postActiveBot({ commit }, input) {
      axios.post('api/v1/bot/active', input).then((res) => {
        if (res) {
          commit('ACTIVE_BOT_SUCCESS', res);
        }
      });
    },
    req_postShowOffBot({ commit }, input) {
      axios.post('api/v1/bot/action-status', input).then((res) => {
        if (res) {
          commit('SHOW_OFF_BOT_SUCCESS', res);
        }
      });
    },
    req_postHistoryBot({ commit }, input) {
      axios
        .get('api/v1/wallet/history-bot-transaction', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_HISTORY_BOT', res);
          }
        });
    },
    req_getHistoryBotProfit({ commit }, input) {
      axios
        .get('api/v1/wallet/history-bot-transaction', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_HISTORY_BOT_PROFIT', res);
          }
        });
    },
  },

  mutations: {
    GET_LIST_TRADING_SYMBOL(state, data) {
      state.listTradingSymbol = data;
    },
    GET_LIST_STRATEGE(state, data) {
      state.listStratege = data;
    },
    GET_DETAILS_BOT(state, data) {
      state.detailBot = data;
    },
    UPDATE_BOT_SUCCESS() {
      this.dispatch('bot/req_getDetailBot');
    },
    ACTIVE_BOT_SUCCESS() {
      this.dispatch('wallet/req_getInfoDashboard');
      this.dispatch('bot/req_getDetailBot');
    },
    SHOW_OFF_BOT_SUCCESS() {
      this.dispatch('bot/req_getDetailBot');
    },
    SET_HISTORY_BOT(state, data) {
      state.historyBot = data;
    },
    SET_HISTORY_BOT_PROFIT(state, data) {
      state.historyBotProfit = data;
    },
  },
};
