/* eslint-disable camelcase */
const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    member: {
      list: [],
      total: 1,
      current: 1,
      perPage: 20,
    },
    statistical: {
      children: {
        f1: 0,
        f2: 0,
        f3: 0,
        f4: 0,
        f5: 0,
        f6: 0,
      },
      total_member: 0,
    },
    referral: null,
  }),

  getters: {
    Member: (state) => state.member,
    Statistical: (state) => state.statistical,
    Referral: (state) => state.referral,
  },

  actions: {
    req_getListMember({ commit }, input) {
      axios
        .get('api/v1/agency/member-list', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_LIST_MEMBER', res);
          }
        });
    },
    req_getStatistical({ commit }) {
      axios.get('api/v1/agency/referral-program').then((res) => {
        if (res) {
          commit('SET_STATISTICAL', res);
        }
      });
    },
    req_getReferralLink: ({ commit }) => {
      axios.get('api/v1/agency/link-ref').then((res) => {
        commit('SET_LINK_REFERRAL', res);
      });
    },
  },

  mutations: {
    SET_LIST_MEMBER(state, member) {
      const {
        data, last_page, current_page, per_page, total,
      } = member.Member;

      state.member = {
        list: data,
        total: last_page,
        current: current_page,
        perPage: per_page,
        member: total,
      };
    },
    SET_STATISTICAL(state, statistical = {}) {
      Object.keys(statistical).forEach((key) => {
        if (key !== 'total_member') {
          state.statistical.children[key] = statistical[key];
        }
        if (key === 'total_member') {
          state.statistical.total_member = statistical[key];
        }
      });
    },
    SET_LINK_REFERRAL(state, data) {
      state.referral = data;
    },
  },
};
