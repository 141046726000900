<template>
  <div class="header">
    <div class="left-header">
      <router-link :to="{ name: 'Member' }">
        <div class="logo-section">
          <!-- <div class="logo-img">
            <img src="~@/assets/images/logo/light.png" alt="" />
          </div> -->
          <!-- <div class="text-logo">
            <span class="eggsbook">ESB</span>
            <span class="effect"> Fx</span>
          </div> -->
        </div>
      </router-link>
      <div class="menu-icon">
        <b-button variant="outline-success" @click.prevent="ChangeMenu()">
          <img src="~@/assets/images/icons/bar.png" alt="" />
        </b-button>
      </div>
    </div>
    <div class="right-header">
      <div class="notification-section" v-if="false">
        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content> &#x1f50d;<span class="sr-only">Search</span> </template>
          <b-dropdown-item href="#">Action</b-dropdown-item>
          <b-dropdown-item href="#">Another action</b-dropdown-item>
          <b-dropdown-item href="#">Something else here...</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="wallet">
        <p>
          {{
            UserInfo.address
              ? add3Dots(UserInfo.address, 6) +
                UserInfo.address.slice(UserInfo.address.length - 6, UserInfo.address.length)
              : 'Not Update'
          }}
        </p>
      </div>
      <div class="profile-section">
        <b-dropdown
          size="md"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          class="item-header"
        >
          <template #button-content>
            <div class="avatar">
              <img src="~@/assets/images/icons/user.png" alt="" />
            </div>
          </template>
          <b-dropdown-item split :to="{ name: 'Profile' }">
            <i class="far fa-user-cog"></i> Profile
          </b-dropdown-item>
          <b-dropdown-item split :to="{ name: 'Accounts' }" v-if="false">
            <i class="fas fa-list-alt"></i> Accounts
          </b-dropdown-item>
          <b-dropdown-item split :to="{ name: 'Support' }">
            <i class="fas fa-comment-lines"></i> Support Center
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="onLogout()">
            <i class="fad fa-power-off"></i> Logout
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
    }),
  },
  methods: {
    ChangeMenu() {
      this.$emit('show-menu', true);
    },
    onLogout() {
      this.$emit('logout', true);
    },
    add3Dots(text, limit) {
      const dots = '...';
      if (text.length > limit) {
        // eslint-disable-next-line no-param-reassign
        text = `${text.substring(0, limit)} ${dots} `;
      }

      return text;
    },
  },
};
</script>

<style lang="scss">
.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 3.9375rem;
  background-color: rgb(0 0 20 / 73%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 80px;
  z-index: 1010;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .left-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 270px;
    @media (max-width: 1200px) {
      justify-content: flex-start;
    }
    a {
      text-decoration: none;
    }
    .logo-section {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .logo-img {
        img {
          height: 2.5rem;
          width: auto;
        }
      }
      .text-logo {
        margin-left: 5px;
        span {
          &.effect {
            background: #009750;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            line-height: 120%;
          }
          &.eggsbook {
            font-size: clamp(14px, 1.5vw, 18px);
            text-transform: uppercase;
            font-weight: 900;
            color: #5d5d5d;
            line-height: 120%;
          }
        }
      }
    }
    .menu-icon {
      margin-left: 3.25rem;
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 374px) {
        margin-left: 5px;
      }
      button {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        background: transparent;
        color: #fff;
        border: 0;
        img {
          width: 35px;
        }
      }
    }
  }

  .right-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .wallet {
      padding: 5px 10px;
      border: 1px solid #0c83e9;
      border-radius: 5px;
      margin: 0px 5px;
      p {
        margin-bottom: 0px;
        font-weight: bold;
        color: #0c83e9;
      }
    }
    .item-header {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
      }
    }
    .avatar {
      color: #6c757d;
      box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
