const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    subject: [],
    list: [],
    detail: [],
  }),

  getters: {
    Subject: (state) => state.subject,
    List: (state) => state.list,
    Detail: (state) => state.detail,
  },

  actions: {
    req_SetUpTick({ dispatch }) {
      dispatch('req_getSubject');
      dispatch('req_getListSubject');
    },
    req_getSubject({ commit }) {
      axios.get('api/v1/ticket/subject').then((res) => {
        if (res) {
          commit('SET_SUBJECT', res);
        }
      });
    },
    req_getListSubject({ commit }) {
      axios.get('api/v1/ticket').then((res) => {
        if (res) {
          commit('SET_LIST_SUBJECT', res);
        }
      });
    },
    req_postSubject({ commit }, input) {
      axios.post('api/v1/ticket/post-ticket', input).then((res) => {
        commit('POST_SUBJECT_SUCCESS');
      });
    },
    req_getTicketDetail({ commit }, input) {
      axios.get(`api/v1/ticket/get-ticket-detail/${input}`).then((res) => {
        commit('DETAIL_TICKET', res);
      });
    },
  },

  mutations: {
    SET_SUBJECT(state, data) {
      state.subject = data.listSubject;
    },
    POST_SUBJECT_SUCCESS() {
      this.dispatch('support/req_getListSubject');
    },
    SET_LIST_SUBJECT(state, data) {
      state.list = data.ticket;
    },
    DETAIL_TICKET(state, data) {
      state.detail = data.ticketDetail;
    },
  },
};
