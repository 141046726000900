const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    commission: {
      list: [],
      total: 1,
      current: 1,
      perPage: 20,
    },
    wallet: {
      list: [],
      total: 1,
      current: 1,
      perPage: 20,
    },
  }),

  getters: {
    Commission: (state) => state.commission,
    Wallet: (state) => state.wallet,
  },

  actions: {
    req_getHistoryWallet: ({ commit }, input) => {
      axios
        .get('api/v1/wallet/history', {
          params: input,
        })
        .then((res) => {
          if (res) {
            commit('SET_HISTORY_WALLET', res);
          }
        });
    },
  },

  mutations: {
    SET_HISTORY_WALLET(state, wallet) {
      const {
        data, last_page, current_page, per_page,
      } = wallet.history;
      state.wallet = {
        list: data,
        total: last_page,
        current: current_page,
        perPage: per_page,
      };
    },
  },
};
