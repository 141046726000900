const axios = require('./axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    error: '',
    success: '',
    type_account: [],
    notifileList: [],
    maintainers: 0,
    stop401: 1,
  }),

  getters: {
    Error: (state) => state.error,
    Success: (state) => state.success,
    typeAccount: (state) => state.type_account,
    NotifileList: (state) => state.notifileList,
    Maintainers: (state) => state.maintainers,
  },

  actions: {
    req_getTypeAccount({ commit }) {
      axios.get('api/v1/list-user-type').then((res) => {
        if (res) {
          commit('SET_ACCOUNT_TYPE', res);
        }
      });
    },
    req_getNotificationImage({ commit }) {
      axios.get('api/v1/notification-image').then((res) => {
        if (res) {
          commit('SET_NOTIFICATION_IMAGE', res);
        }
      });
    },
    req_getSettings({ commit }) {
      axios.get('api/v1/setting-main').then((res) => {
        if (res) {
          commit('SET_SETTING', res);
        }
      });
    },
  },

  mutations: {
    SUCCESS_MESSAGE(state, message) {
      state.success = message;
    },
    ERROR_MESSAGE(state, message) {
      state.error = message;
    },
    ERROR_500(state, message) {
      state.status = message;
    },
    SET_ACCOUNT_TYPE(state, data) {
      state.type_account = data;
    },
    SET_NOTIFICATION_IMAGE(state, data) {
      state.notifileList = data.system;
    },
    SET_SETTING(state, data) {
      state.maintainers = data.maintenance_excahnge.maintenance_sys;
    },
    RESET_401(state, data) {
      state.stop401 = data;
    },
  },
};
