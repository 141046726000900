<template>
  <div class="auth">
    <b-row class="mx-0">
      <b-col lg="12" cols="12" class="mx-auto p-0">
        <div class="right-auth">
          <!-- <img src="~@/assets/images/logo/light.png" alt="" />
          <h5>Welcome To ESB FX</h5>
          <h1 class="">Let's Get Started</h1>
          <h6 class="my-4 help">Community!</h6>
          <div class="help-container">
            <a href="https://www.facebook.com/esbfx" target="_blank" v-b-tooltip.hover title="Fanpage Facebook">
              <img src="~@/assets/images/icons/facebook.png" alt="" />
            </a>
            <a href="https://t.me/esbfx_ann" target="_blank" v-b-tooltip.hover title="Telegram Group Community">
              <img src="~@/assets/images/icons/telegram.png" alt="" />
            </a>
            <a href="https://twitter.com/ESBFX" target="_blank" v-b-tooltip.hover title="Twitter Page">
              <img src="~@/assets/images/icons/Twitter.png" alt="" />
            </a>
            <a href="https://bit.ly/30KjcL5" target="_blank" v-b-tooltip.hover title="Youtube Channel">
              <img src="~@/assets/images/icons/youtube.png" alt="" />
            </a>
          </div> -->
        </div>
        <div class="left-auth px-3">
          <router-view></router-view>
          <Loading />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="notification"
      title=""
      hide-footer
      body-class="p-0"
      header-class="p-0"
      modal-class="d-flex justify-content-center flex-column"
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="notifi in NotifileList"
          :key="notifi.image"
          class="img-carousel"
          :img-src="notifi.image"
        ></b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Loading from '@/components/shared/Loading.vue';

export default {
  components: { Loading },
  data() {
    return {
      slide: 0,
    };
  },
  computed: {
    ...mapGetters({
      NotifileList: 'core/NotifileList',
    }),
  },
  watch: {
    NotifileList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$bvModal.show('notification');
        }
      },
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  width: 100%;
  height: 100%;
  height: 100vh;
  max-height: 100vh;

  .left-auth {
    max-width: 500px;
    box-shadow: -10px 22px 90px 0 rgb(0 0 0 / 8%);
    position: fixed;
    flex: 1;
    transform: unset;
    top: 0;
    bottom: 0;
    left: unset;
    right: 0;
    display: flex;
    flex-direction: column;
    background: rgb(0 0 20 / 73%);
    z-index: 1;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 50%;
    @media (min-width: 576px) {
      max-height: 100vh;
      padding: 10px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .right-auth {
    background: url('~@/assets/images/background/bg.png'), #f8f8f8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    img {
      width: 100%;
      max-width: 120px;
      height: 115px;
    }
    h5 {
      color: #fff;
      margin: 40px auto 5px auto;
      font-size: clamp(15px, 1.5vw, 17px);
      font-weight: 600;
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 3px;
        width: 40%;
        margin: auto;
        background: #009750;
      }
    }
    h1 {
      color: #fff;
      margin: 20px auto 5px auto;
      font-size: clamp(19px, 2.5vw, 35px);
      font-weight: 600;
    }
    h6.help {
      color: #fff;
      font-size: clamp(15px, 1vw, 16px);
      font-weight: 600;
      margin: auto;
      width: 100%;
      max-width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        display: inline-block;
        margin: 0 14px 4px 0;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
      &:after {
        display: inline-block;
        margin: 0 0 4px 14px;
        height: 2px;
        content: ' ';
        text-shadow: none;
        background-color: #e9ecef;
        width: 25%;
      }
    }
    .help-container {
      a {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
