const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    user: {
      email: '',
      id: '',
      adddress: '',
      confirm_pass: false,
      confirm_email: false,
    },
    kyc: {
      passport_image: '',
      passport_image_selfie: '',
      passport: '',
      status: -1,
    },
    auth: {
      enable: false,
      qr: '',
      secret: '',
    },
    statistical: {},
    infoPhoneTelegram: {},
    infoBinance: {},
  }),

  getters: {
    UserInfo: (state) => state.user,
    UserKYC: (state) => state.kyc,
    UserAuth: (state) => state.auth,
    Statistical: (state) => state.statistical,
    InfoPhoneTelegram: (state) => state.infoPhoneTelegram,
    InfoBinance: (state) => state.infoBinance,
  },

  actions: {
    req_getInfo({ commit }) {
      axios.get('api/v1/auth/get-user-info').then((res) => {
        if (res) {
          commit('GET_INFO_SUCCESS', res);
        }
      });
    },
    req_postUpdateInfo({ commit }, input) {
      axios.post('api/v1/update-info', input).then((res) => {
        if (res) {
          commit('UPDATE_INFO_SUCCESS', res);
        }
      });
    },
    req_getAuth({ commit }) {
      axios.get('api/v1/profile/get-auth').then((res) => {
        if (res) {
          commit('GET_INFO_AUTH', res);
        }
      });
    },
    req_postAuth({ commit }, input) {
      axios.post('api/v1/profile/confirm-auth', input).then((res) => {
        if (res) {
          commit('UPDATE_AUTH_SUCCESS', res);
        }
      });
    },
    req_postChangePassword({ commit }, input) {
      axios.post('api/v1/profile/update-password', input).then((res) => {
        if (res) {
          commit('UPDATE_PASSWORD_SUCCESS', res);
        }
      });
    },
    req_postUpdateAddress({ commit }, input) {
      axios.post('api/v1/profile/update-address-wallet', input).then((res) => {
        if (res) {
          commit('UPDATE_ADDRESS_SUCCESS', res);
        }
      });
    },
    req_postUpdateAddressTronLink({ commit }, input) {
      axios.post('api/v1/profile/update-address-wallet-tron', input).then((res) => {
        if (res) {
          commit('UPDATE_ADDRESS_TRONLINK_SUCCESS', res);
        }
      });
    },
    req_postUpdateEmail({ commit }, input) {
      axios.post('api/v1/profile/update-email', input).then((res) => {
        if (res) {
          commit('UPDATE_EMAIL_SUCCESS', res);
        }
      });
    },
    req_postKYC({ commit }, input) {
      const formData = new FormData();
      formData.append('passport', input.passport);
      formData.append('passport_image', input.passport_image);
      formData.append('passport_image_selfie', input.passport_image_selfie);
      formData.append('type', input.type);

      axios
        .post('api/v1/profile/upload-kyc', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response !== null && response !== undefined) {
            commit('UPDATE_KYC_SUCCESS', response);
          }
        });
    },
    req_getKYC({ commit }) {
      axios.get('api/v1/profile/get-kyc').then((res) => {
        if (res) {
          commit('GET_INFO_KYC', res);
        }
      });
    },
    req_getInfoPhoneTelegram({ commit }) {
      axios.get('api/v1/profile/get-phone-telegram').then((res) => {
        if (res) {
          commit('GET_INFO_PHONE_TELEGRAM', res);
        }
      });
    },
    req_postConnectPhoneTelegram({ commit }, input) {
      axios.post('api/v1/profile/post-phone-telegram', input).then((res) => {
        if (res) {
          commit('CONNECT_PHONE_TELEGRAM_SUCCESS', res);
        }
      });
    },
    req_getInfoBinance({ commit }) {
      axios.get('api/v1/profile/get-binance').then((res) => {
        if (res) {
          commit('GET_INFO_BINANCE', res);
        }
      });
    },
    req_postConnectBinance({ commit }, input) {
      axios.post('api/v1/profile/post-binance', input).then((res) => {
        if (res) {
          commit('CONNECT_BINANCE_SUCCESS', res);
        }
      });
    },
    req_resendMail({ commit }, input) {
      axios.post('api/v1/resend-mail', input).then((res) => {
        if (res) {
          commit('RESEND_MAIL_SUCCESS', res);
        }
      });
    },
  },

  mutations: {
    GET_INFO_SUCCESS(state, data) {
      // console.log(data);
      state.user = {
        email: data.user.email,
        confirm_pass: data.user.confirm_pass,
        confirm_email: data.user.confirm_email,
        id: data.user.userId,
        address: data.user.User_WalletAddress ?? null,
        addressTron: data.user.User_WalletAddressTron ?? null,
        surname: data.user.info_surname ?? null,
        name: data.user.info_name ?? null,
        birthday: data.user.info_birthday ?? null,
        // address: data.user.info_address ?? null,
        phone: data.user.info_phone ?? null,
        zipCode: data.user.info_zipCode ?? null,
        placeBirthday: data.user.info_placeBirthday ?? null,
        level: data.user.level ?? 0,
        country: data.user.info_country ?? 0,
        url: data.base_url,
      };
      state.statistical = { ...data.package, ...data.statisticalAgency };
    },
    UPDATE_INFO_SUCCESS() {
      this.dispatch('user/req_getInfo');
    },
    UPDATE_KYC_SUCCESS() {
      this.dispatch('user/req_getKYC');
    },
    UPDATE_AUTH_SUCCESS(state, data) {
      this.dispatch('user/req_getAuth');
      // console.log(data);
    },
    UPDATE_PASSWORD_SUCCESS() {
      this.commit('auth/LOGOUT_SUCCESS');
    },
    UPDATE_ADDRESS_SUCCESS() {
      this.dispatch('user/req_getInfo');
    },
    UPDATE_ADDRESS_TRONLINK_SUCCESS() {
      this.dispatch('user/req_getInfo');
    },
    UPDATE_EMAIL_SUCCESS() {
      this.dispatch('user/req_getInfo');
    },
    GET_INFO_KYC(state, data) {
      state.kyc = data;
    },
    GET_INFO_AUTH(state, data) {
      state.auth = {
        enable: data.enable,
        qr: data.qr,
        secret: data.secret,
      };
    },
    GET_INFO_PHONE_TELEGRAM(state, data) {
      state.infoPhoneTelegram = data;
    },
    CONNECT_PHONE_TELEGRAM_SUCCESS() {
      this.dispatch('user/req_getInfo');
      this.dispatch('user/req_getInfoPhoneTelegram');
    },
    GET_INFO_BINANCE(state, data) {
      state.infoBinance = data;
    },
    CONNECT_BINANCE_SUCCESS() {
      this.dispatch('user/req_getInfo');
      this.dispatch('user/req_getInfoBinance');
    },
    RESEND_MAIL_SUCCESS() { },
  },
};
