const axios = require('../config/axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    demo: [],
    live: [],
    types: [],
  }),

  getters: {
    Demo: (state) => state.demo,
    Live: (state) => state.live,
    Types: (state) => state.types,
  },

  actions: {
    req_postRegisterSub({ commit }, input) {
      axios.post('api/v1/account/register', input).then((res) => {
        if (res) {
          commit('REGISTER_SUB_SUCCESS', res);
        }
      });
    },
    req_postSettingSub({ commit }, input) {
      axios.post('api/v1/account/setting', input).then((res) => {
        if (res) {
          commit('SETTING_SUB_SUCCESS', res);
        }
      });
    },
    req_getListSub({ commit }, input) {
      axios.get('api/v1/account/list', input).then((res) => {
        if (res) {
          commit('SET_LIST_SUB', res);
        }
      });
    },
    req_getTypeSub({ commit }, input) {
      axios.get('api/v1/account/list-type', input).then((res) => {
        if (res) {
          commit('SET_TYPE_SUB', res);
        }
      });
    },
  },

  mutations: {
    REGISTER_SUB_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    SETTING_SUB_SUCCESS() {
      this.dispatch('account/req_getListSub');
    },
    SET_LIST_SUB(state, data) {
      state.live = data.live;
      state.demo = data.demo;
    },
    SET_TYPE_SUB(state, data) {
      state.types = data;
    },
  },
};
