<template>
  <div class="">
    <Header @show-menu="showSidebar = !showSidebar" @logout="onLogout()" />
    <div class="page-container">
      <div class="page-wrapper-info">
        <!-- <div class="info-user">
          <div class="avatar">
            <img src="~@/assets/images/logo/light.png" alt="" />
          </div>
          <div class="info">
            <div class="name">{{ getNameUser }}</div>
            <div class="action-user d-flex align-items-center justify-content-center">
              <router-link :to="{ name: 'Profile' }">
                <b-button variant="none" class="mr-2">
                  <i class="far fa-user-cog"></i>
                </b-button>
              </router-link>
              <b-button variant="none" @click="onLogout()">
                <i class="fad fa-power-off"></i>
              </b-button>
            </div>
          </div>
        </div> -->
        <!-- <div class="page-title">
          <div v-html="page"></div>
        </div> -->
      </div>
      <div class="page-wrapper">
        <transition
          enter-active-class="animate__animated  animate__slideInLeft animate__faster"
          leave-active-class="animate__animated   animate__fadeOutLeft animate__faster"
        >
          <Sidebar v-show="showSidebar" />
        </transition>
        <transition-group
          tag="div"
          name="content"
          class="page-content"
          :class="{ activeMenu: showSidebar }"
        >
          <b-container v-show="showContent" key="content-section" fluid>
            <div class="container-fluid">
                <div class="page-title">
                  <div v-html="page"></div>
                </div>
            </div>
            <router-view></router-view>
          </b-container>
        </transition-group>
      </div>
      <!-- <Footer /> -->
    </div>
    <Loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from './Header.vue';
import Sidebar from './Sidebar.vue';
import Loading from '@/components/shared/Loading.vue';
import Footer from './Footer.vue';

export default {
  components: {
    Header,
    Sidebar,
    Loading,
    Footer,
  },
  data() {
    return {
      showSidebar: true,
      page: '',
      showContent: true,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
    }),
    getNameUser() {
      const user = this.UserInfo;
      if (user.name) {
        return `${user.surname} ${user.name}`;
      }
      return user.email.split('@')[0];
    },
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
      if (window.innerWidth <= 1200) {
        this.showSidebar = false;
      } else {
        this.showSidebar = true;
      }
    },
    onLogout() {
      this.$store.commit('auth/LOGOUT_SUCCESS');
    },
  },
  async mounted() {
    if (window.innerWidth <= 1200) {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
    }
    this.page = this.$route.meta.page;
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route(to, from) {
      this.page = to.meta.page;
      if (to.name !== from.name && window.innerWidth <= 767) {
        this.showSidebar = false;
      }
    },
    showSidebar: {
      handler() {
        this.showContent = false;

        this.$nextTick(() => {
          this.showContent = true;
        });
      },
    },
  },
};
</script>

<style lang="scss">
.content-enter-active,
.content-leave-active {
  transition: width 10s ease-in-out;
}
@media (min-width: 1201px) {
  .activeMenu {
    max-width: calc(100% - 270px);
  }
}
.page-container {
  // padding-top: 3.9375rem;
  background: url(~@/assets/images/background/bg.png);
  background-size: cover;
  background-position: center center;
  .page-title {
    margin-left: 0%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    min-height: 50px;
    div {
      color: #fff;
      text-transform: uppercase;
      span {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  .page-wrapper-info {
    // background-image: url(~@/assets/images/background/overlay.png);
    background-size: cover;
    background-position: center center;
    // min-height: 275px;
    // -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    // box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    // padding: 4rem 80px 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .info-user {
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      .avatar {
        padding: 0.25rem;
        background-color: #a8a8b1;
        border: 1px solid #6c757d;
        max-width: 100%;
        height: auto;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        padding: 6px;
        img {
          background: white;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding-left: 20px;
        .name {
          color: #fff;
          font-weight: 500;
          font-size: clamp(15px, 1.25vw, 18px);
        }
        .action-user {
          margin-top: 7px;
          button {
            border-radius: 50%;
            border: 0;
            background: #009750;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            padding: 5px;
            color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 9px 12px rgba(0, 0, 0, 0.2);
            i,
            svg {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width: 576px) {
      padding: 4.5rem 20px 40px;
      .info-user {
        display: none;
      }
      .page-title {
        align-items: center;
      }
    }
  }
  .page-wrapper {
    width: 100%;
    position: relative;
    min-height: 100vh;
    margin-top: 0px;
    padding: 0px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .page-content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1 1 auto;
      width: 100%;
      min-height: calc(100vh - 70px);
      position: relative;
      padding: 120px 30px 70px;
      transition: 0.3s;
    }
    .activeMenu{
      margin-left: 270px;
      @media (max-width: 1200px) {
        margin-left: 0px;
      }
    }
    @media (max-width: 767px) {
      padding: 0;
      margin-top: -30px;
      .page-content {
        padding: 0 0 60px;
      }
    }
    
  }
}
</style>
